.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
}

.logo-img {
  width: 6%;
}
.three-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
}
.img-pc-room {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  border: 1px solid #f0f0f0;
}
.room-card-container {
  width: 99%;
  background: #f9f9f9;
  border-radius: 10px;
  height: 85vh !important;
  padding-top: 40px !important;
  box-shadow: rgb(185 194 204) 2px 2px 6px;
  overflow-y: auto;
}

.room-card-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px;
}
.rotate-icon {
  cursor: pointer;
  width: 25px;
  height: 25px;
  color: rgb(204 204 204);
  transition: color 0.3s ease;
}

.rotate-icon:hover {
  color: green;
}

.room-card {
  border: 1px solid #e5e5e5;
  padding: 15px;
  background: #fff !important;
  display: flex;
  flex-direction: column;
  /* height: 200px; */
  width: 350px;
  justify-content: flex-end !important;
  box-shadow: rgba(14, 63, 126, 0.04) 0px 0px 0px 1px,
    rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px,
    rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px,
    rgba(42, 51, 70, 0.04) 0px 6px 6px -3px,
    rgba(14, 63, 126, 0.04) 0px 12px 12px -6px,
    rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}
/* .room-card {
  border: 1px solid #e5e5e5;
  border-radius: 15px;
  padding: 15px;
  background: linear-gradient(to right, #ffffff, #f0f8ff);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
} */
.room-card-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  justify-content: center;
}

.room-card-info {
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.session-room-name {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.room-name {
  font-weight: bold;
  font-size: 16px;
  word-break: break-word;
}

.room-created-at {
  font-size: 12px;
  color: #888;
  text-align: center;
}

.settings-icon {
  cursor: pointer;
  transition: fill 0.3s ease, transform 0.3s ease;
}

.settings-icon:hover {
  fill: #3e7325;
  transform: scale(1.1);
}

.room-card-container {
  width: 99%;
  background: #f9f9f9;
  border-radius: 10px;
  height: 100vh;
  padding: 10px;
  box-shadow: rgb(185 194 204) 2px 2px 6px;
}

.room-card-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.room-card:hover {
  transform: translateY(-5px);
  box-shadow: rgb(185 194 204) 4px 4px 10px;
}

.room-card-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.room-card-info {
  padding-left: 15px;
}

.room-name {
  font-weight: bold;
  font-size: 16px;
}

.room-created-at {
  font-size: 12px;
  color: #888;
  text-align: center;
}

.three-dots-room {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.dots-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.rotate-icon {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dropdown-menu-card-bottom {
  position: absolute;
  top: 40px;
  right: 10px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
  list-style: none;
  padding: 10px 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 10px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align to the left */
  justify-content: center;
  width: 180px;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu li {
  padding: 12px 15px;
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  list-style: none;
}

.dropdown-menu li:hover {
  background-color: #f8f8f8;
}

/* .dropdown-divider {
  width: 200%;
  height: 1px;
  background-color: #ddd;
  border: none;
  margin: 5px 0;
} */
.footer-card {
  display: flex;
  justify-content: flex-end;
}
.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  /* width: 60%; */
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 30px;
}

.image-grid img {
  width: 100px;
  height: 100px;
  border-radius: 4px;
}
.close-btn {
  border-radius: 50px;
  border: none;
  padding: 5px 10px;
  font-weight: 900;
  background-color: #ff4d4d;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.close-btn:hover {
  background-color: #ff1a1a;
  transform: scale(1.1);
}
@media (max-width: 1200px) {
  .room-card-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 900px) {
  .room-card-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .room-card {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .room-card-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .modal-content {
    width: 90%;
  }
  .image-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 600px) {
  .container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .logo-img {
    width: 20%;
  }
}
@media (max-width: 600px) {
  .dots-icon svg {
    width: 24px;
    height: 24px;
  }

  .footer-card {
    justify-content: center;
  }
}
@media (max-width: 480px) {
  .modal-content {
    width: 95%;
    padding: 15px;
  }

  .image-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
.room-name {
  word-break: break-word;
}

.room-card {
  max-width: 100%;
}
@media (max-width: 768px) {
  .room-card-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .search-bar,
  .footer-card {
    width: 100%;
    justify-content: center;
  }

  .modal-content {
    width: 90%;
  }

  .dots-icon svg {
    width: 22px;
    height: 22px;
  }
}
@media (max-width: 1024px) {
  .room-card-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }

  .room-card {
    width: 90%;
    margin: 0 auto;
  }

  .container {
    flex-direction: row;
    justify-content: space-between;
  }

  .logo-img {
    width: 10%;
  }

  .modal-content {
    width: 80%;
  }
}
@media (max-width: 768px) {
  .room-card {
    max-width: 90%;
    padding: 10px;
  }

  .img-pc-room {
    max-width: 100%;
    height: auto;
  }

  .footer-card {
    justify-content: space-around;
  }
}

@media (max-width: 480px) {
  .room-card {
    max-width: 100%;
    padding: 8px;
  }

  .img-pc-room {
    max-width: 100%;
    height: auto;
  }

  .session-room-name .room-name {
    font-size: 1rem;
  }

  .footer-card {
    flex-direction: column;
    align-items: center;
  }

  .rotate-icon {
    width: 20px;
    height: 20px;
  }
}
