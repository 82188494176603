.initial-meeting-container {
  display: flex;
  padding: 20px;
  flex-direction: column;
  gap: 10px;
}

.initial-meeting-logo {
  width: 120px;
  height: 80px;
  padding-left: 20px;
}

.initial-meeting-title {
  display: flex;
  justify-content: center;
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
}

.initial-meeting-settings-container {
  display: flex;
  align-items: center;
  gap: 50px;
  padding: 10px;
  border: 3px solid #ddd;
  border-radius: 15px;
  flex-direction: row-reverse;
}

.initial-meeting-form {
  width: 600px;
  display: flex;
  flex-direction: column;
  background: rgba(225, 225, 225, 0.1);
  justify-content: center;
  border-radius: 10px;
  gap: 20px;
  padding: 20px;
}

.initial-meeting-label {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.initial-meeting-icon {
  display: flex;
  align-items: center;
  gap: 20px;
}

.initial-meeting-mic-settings {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.computer-audio-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.mic-range-meeting {
  background-color: #000;
}

.Internal-mic-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggle-button-meeting {
  width: 50px;
  height: 25px;
  border-radius: 15px;
  border: none;
  position: relative;
  cursor: pointer;
  background: #ccc;
  overflow: hidden;
}

.toggle-button-meeting.active {
  background: rgb(75, 136, 43);
}

.toggle-indicator-meeting {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
  transition: 0.2s ease-in-out;
}

.toggle-indicator-meeting.active {
  left: 27px;
}

.toggle-button-meet {
  width: 45px;
  height: 20px;
  border-radius: 15px;
  border: none;
  position: relative;
  cursor: pointer;
  background: #ccc;
  overflow: hidden;
}

.toggle-button-meet.active {
  background: #000;
}

.toggle-indicator-meet {
  position: absolute;
  top: 1px;
  left: 0px;
  width: 18px;
  height: 18px;
  background: #fff;
  border-radius: 50%;
  transition: 0.2s ease-in-out;
}

.toggle-indicator-meet.active {
  left: 28px;
}

.audio-controller {
  display: flex;
  justify-content: space-between;
}

.audio-icons-svg-controlller {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.audio-icons-svg {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.action-buttons-meeting {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
}

.cancel-button-meeting {
  padding: 10px 20px;
  background: #fff;
  color: rgb(75, 136, 43);
  border-radius: 10px;
  border: 1px solid rgb(75, 136, 43);
  font-size: 24px;
  line-height: 36px;
  font-weight: 400;
}

.join-button-meeting {
  padding: 10px 20px;
  background-color: rgb(75, 136, 43);
  color: #fff;
  border-radius: 10px;
  border: none;
  font-size: 20px;
}

.video-container-meeting {
  position: relative;
  width: 100%;
  height: 70vh;
  background-color: #e5e7eb;
  border-radius: 20px;
  overflow: hidden;
}

.local-video-meeting {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scaleX(-1);
}

.camera-off-meeting {
  position: absolute;
  inset: 0;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.control-buttons-meeting {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 16px;
}

.control-button-meeting {
  width: 50px;
  height: 50px;
  background-color: #c72c2c;
  border-radius: 50%;
  border: none;
  color: #fff;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.control-button-meeting.muted {
  background-color: #c72c2c;
}

#initial_vc_head {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  height: 56px;
  padding: 0px 20px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
  display: flex;
  align-items: center;
  z-index: 10;
  background: white;
}

#initial_vc_master {
  /* top: 56px; */
  width: 100%;
  min-height: calc(100vh - 56px);
  position: absolute;
  display: flex;
  justify-content: center;
  height: fit-content;
  z-index: 1;
}

#initial_vc_child {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1080px;
  /* background-color: red; */
  gap: 40px;
}


#initial_vc_video_master {
  height: auto;
  aspect-ratio: 4/3;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto;
  aspect-ratio: 9/5;
  border-radius: 10px;
}

#initial_vc_video_form {
  width: 300px;
  min-width: 300px;
}

#initial_vc_video_vid {
  width: 100%;
  object-fit: cover;
  /* display: none; */
}

.initial_vc_link_cancel {
  height: 35px;
  background: white;
  border-radius: 7px;
  width: 100%;
  display: flex;
  align-items: center;
  color: black;
  font-weight: 500;
  font-size: 14px;
  justify-content: center;
  text-decoration: none;
  box-shadow: var(--box-shadow-btn);
}

.initial_vc_link_cancel:hover {
  box-shadow: var(--box-shadow-btn-hover);
}

.initial_vc_link_join {
  height: 35px;
  background: var(--btn-primary);
  font-weight: 500;
  font-size: 15px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;
  outline: none;
  border: none;
  justify-content: center;
  width: 100%;
}

.initial_vc_link_join:hover {
  background: var(--btn-primary-hover);
}

.initial_vc_for_inp {
  height: 40px;
  padding: 0px 7px;
  border-radius: 7px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
}

#initial_vc_video_fr_h1 {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
}

#initial_vc_video_media_opts_btns_master {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  z-index: 2;
  justify-content: center;
  padding: 10px 0px;
}

.initial_vc_video_media_opts_btns_bt {
  width: 56px;
  height: 56px;
  background: none;
  outline: none;
  display: flex;
  align-items: center;
  border-radius: 100px;
  cursor: pointer;
  justify-content: center;
}

.initial_vc_video_media_opts_btns_b_icon {
  font-size: 20px;
  color: white;
  min-width: 20px;
}

#initial_vc_video_media_opts_btns_overlay {
  background-image: linear-gradient(#0000, rgba(0, 0, 0, 0.5) 100%);
  height: 90px;
  width: 100%;
  z-index: 1;
  position: absolute;
  bottom: 0;
}

#initial_vc_video_media_opts_btns_overla_cam {
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  color: white;
  position: absolute;
  background: rgb(40, 40, 40);
}