body {
  margin: 0;
  font-family: Arial, sans-serif;
  /* background: linear-gradient(to bottom, #a8e063, #56ab2f); */
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.login-card {
  border-radius: 10px;
  padding: 20px;
  width: 27%;
  height: 50vh;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.bg-logo {
  width: 30%;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
  color: #625b5b;
}

input[type="email"],
input[type="password"] {
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
}

input[type="checkbox"] {
  margin-right: 5px;
}

.login-button {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.login-button:hover {
  background-color: #45a049;
}

.error {
  color: red;
  font-size: 14px;
  margin-bottom: 15px;
}

p {
  margin-top: 10px;

}