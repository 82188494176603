.profile1-container {
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
  min-height: 100vh;
}

.header-profile {
  display: flex;
  justify-content: space-between;
  background: rgb(255, 255, 255);
  padding: 10px 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px;
  position: relative;
  z-index: 10;
}
.avatar-user {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 10px;
}

.logo-beep-img {
  height: 60px;
  width: 100px;
  cursor: pointer;
}

.user-info-1 {
  display: flex;
  align-items: center;
  gap: 30px;
}

.user-name-1 {
  color: grey;
  font-weight: normal;
  font-size: 16px;
}

.avatar-container-1 {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #e9ecef;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-user {
  border-radius: 10px;
}
.delete-account {
  background: #c82333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
}

.delete-account:hover {
  background: #a71d2a;
  transform: scale(1.05);
}
.p-del {
  border: 1px solid #ddd;
  padding: 30px;
  border-radius: 10px;
}
.button-cancel {
  cursor: pointer;
  border-radius: 8px;
  transition: background 0.3s ease, transform 0.2s ease;
  background-color: #f8f9fa;
  font-weight: bold;
  color: #343a40;
  border: 1px solid #ddd;
  padding: 10px 50px;
}
.button-cancel:hover {
  background: rgb(234, 232, 232);
  transform: scale(1.05);
}
.profile-content-profile {
  display: flex;
  margin: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.sidebar-profile {
  width: 250px;
  background-color: #f1f4f9;
  padding: 20px;
}

.sidebar-item.account-info-profile {
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: #28a745;
  color: white;
  font-weight: bold;
  align-items: center !important;
}
.sidebar-item-profile {
  cursor: pointer;
  padding: 10px;
  margin-bottom: 10px;
  width: 200px !important;
  height: 30px !important;
  border-radius: 15px !important;
  align-items: center !important;
  display: flex;
}

.sidebar-item-profile.active {
  background-color: green;
  color: white;
  font-weight: bold;
}

.sidebar-item-profile {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  color: #495057;
}

.main-content-profile {
  padding: 30px;
  background-color: #ffffff;
  display: flex;
  gap: 760px;
}

.form-section-profile {
  max-width: 500px;
  margin-bottom: 30px;
}

.form-section-profile h2 {
  margin-bottom: 20px;
  color: #343a40;
}

.form-group-profile {
  margin-bottom: 20px;
  width: 300%;
}

.form-group-profile select,
.form-group-profile input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.button-container-profile {
  display: flex;
  gap: 15px;
}

.button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.button.reset {
  background-color: #f8f9fa;
  font-weight: bold;
  color: #343a40;
  border: 1px solid #ddd;
  padding: 10px 50px;
}

.button.reset:hover {
  background-color: #e2e6ea;
  transform: scale(1.05);
}

.button.update-profile {
  background-color: #28a745;
  font-weight: bold;
  color: white;
  padding: 10px 50px;
}

.button.update-profile:hover {
  background-color: #218838;
  transform: scale(1.05);
}

.avatar-section-profile {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.avatar-upload-profile {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f8f9fa;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border: 4px solid #ddd;
}

.avatar-upload-profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar-upload-placeholder-profile {
  width: 100%;
  height: 100%;
}

.upload-button-profile {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.upload-button-profile:hover {
  background-color: #218838;
  transform: scale(1.05);
}

.delete-avatar-button-profile {
  padding: 10px 20px;
  background-color: #dc3545 !important;
  color: white !important;
  border: none;
  border-radius: 5px;
  cursor: pointer !important;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.delete-avatar-button-profile:hover {
  background-color: #c82333;
  transform: scale(1.05);
}

.delete-avatar-button-profile.disabled {
  background-color: #f8f9fa;
  color: #ced4da;
  cursor: not-allowed;
}

.btn-avatars-profile-del-update {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.avatar-container {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.avatar-preview {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #d1f8d2 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.avatar-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar-placeholder {
  width: 100%;
  height: 100%;
}

.avatar-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.upload-avatar-button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
}

.hidden-input {
  display: none;
}

.avatar-section {
  text-align: center;
  margin-top: 20px;
}

.avatar-preview {
  position: relative;
  display: inline-block;
}

.avatar-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.upload-button {
  background-color: #4caf50;
  color: white;
  padding: 8px 12px;
  border: none;
  cursor: pointer;
  margin: 5px;
}

.button.delete-avatar {
  background-color: #f44336;
  color: white;
  padding: 8px 12px;
  border: none;
  cursor: pointer;
  margin: 5px;
}

.button.delete-avatar:hover,
.upload-button:hover {
  opacity: 0.8;
}
.profile-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 760px;
}

.avatar-preview {
  width: 120px;
  height: 120px;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f8f9fa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.upload-avatar-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.delete-avatar-button {
  padding: 10px 20px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.delete-avatar-button.disabled {
  background-color: #e9ecef;
  color: #6c757d;
  cursor: not-allowed;
}
