#navigation_options_master {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 72px;
    background: rgb(244, 244, 244);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid rgb(220, 220, 220);
    padding: 10px 0px;
    gap: 5px;
    z-index: 2;
}

#chat_options_master {
    position: fixed;
    left: 72px;
    top: 0;
    height: 100%;
    width: 400px;
    background: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid rgb(220, 220, 220);
    padding: 10px;
    z-index: 2;
}

#chat_options_maste_head {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    padding-left: 3px;
}

#chat_options_maste_search_c_icon {
    font-size: 18px;
    min-width: 18px;
    color: rgb(120, 120, 120);
    margin-right: 4px;
}

#chat_options_maste_search_c_input {
    width: 100%;
    border: none;
    outline: none;
    height: 100%;
}

#chat_options_maste_search_m {
    width: 100%;
    border: 1px solid rgb(230, 230, 230);
    margin-top: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    padding: 0px 7px;
    height: 35px;
    align-items: center;
}

#chat_options_maste_hea_ico_container {
    border-radius: 5px;
    width: 32px;
    min-width: 32px;
    height: 32px;
    min-height: 32px;
    display: flex;
    align-items: center;
    margin-left: auto;
    justify-content: center;
    cursor: pointer;
}

#chat_options_maste_hea_ico_container:hover {
    background: rgb(246, 245, 244);
}

#chat_options_maste_hea_title {
    font-size: 18px;
    font-weight: 600;
}

#chat_options_maste_hea_icon {
    font-size: 20px;
    min-width: 20px;
}

.navigation_options_child {
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* background: rgb(220, 220, 220); */
}

.navigation_options_chil_icon {
    font-size: 24px;
    min-width: 24px;
    color: rgb(92, 92, 92);
}

#chat_options_chats_master {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
    gap: 10px;
}

.chat_options_c_container_master {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-radius: 7px;
    cursor: pointer;
    padding: 10px;
    user-select: none;
    align-items: center;
    text-decoration: none;
    /* justify-content: center; */
}

.chat_options_c_container_master:hover {
    background: rgb(246, 245, 244);
}

.chat_options_c_container_child_img_container {
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
    background: rgb(240, 240, 240);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    overflow: hidden;
}

.chat_options_c_container_child_info_child_h_time {
    margin-left: auto;
    color: rgb(107, 107, 107);
    font-size: 14px;
    white-space: nowrap;
}

.chat_options_c_container_child_info_child_head {
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.chat_options_c_container_child_info_master {
    flex-direction: column;
    display: flex;
    width: 100%;
    gap: 2px;
    overflow: hidden;
}

.chat_options_c_container_child_info_child_h_name {
    font-weight: 600;
    font-size: 14px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: rgb(16, 16, 16);
}

.chat_options_c_container_child_info_child_body {
    font-size: 14px;
    color: rgb(107, 107, 107);
}

.chat_options_c_container_child_img_icon {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: relative;
}

#chat_history_type_master {
    display: flex;
    flex-direction: row;
    bottom: 0;
    left: 472px;
    position: fixed;
    height: 55px;
    width: calc(100% - 472px);
    border-top: 1px solid rgb(220, 220, 220);
    background: rgb(244, 244, 244);
    align-items: center;
    padding: 10px 14px;
    padding-left: 12px;
    cursor: default;
    gap: 10px;
    user-select: none;
    z-index: 2;
}

#chat_history_head_master {
    display: flex;
    flex-direction: row;
    top: 0;
    left: 472px;
    position: fixed;
    height: 55px;
    width: calc(100% - 472px);
    border-bottom: 1px solid rgb(220, 220, 220);
    background: rgb(244, 244, 244);
    align-items: center;
    padding: 0px 20px;
    padding-right: 13px;
    cursor: default;
    user-select: none;
    z-index: 2;
}

#chat_history_head_child_img_master {
    width: 40px;
    margin-right: 10px;
    height: 40px;
    overflow: hidden;
    border-radius: 100px;
}

#chat_history_nam_title {
    color: rgb(37, 37, 37);
    font-size: 15px;
    position: relative;
    top: -1px;
    font-weight: 600;
    margin-right: auto;
}

#chat_history_nam_call_icon {
    font-size: 20px;
    min-width: 20px;
    color: rgb(113, 113, 113);
}

#chat_history_nam_call_ico_container {
    padding: 7px 10px;
    border-radius: 5px;
    margin-left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

#chat_history_nam_call_ico_container:hover {
    background: rgb(236, 236, 236);
}

#chat_history_type_child_inp_master {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    border-radius: 100px;
    background: white;
    overflow: hidden;
    height: 100%;
    border: 1px solid rgb(224, 224, 224);
    padding: 0px 7px;
}

#chat_history_type_child_send_master {
    background: var(--btn-secondary);
    height: 35px;
    min-height: 35px;
    width: 35px;
    min-width: 35px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    outline: none;
    cursor: pointer;
    justify-content: center;
    border: none;
}

#chat_history_type_child_send_icon {
    color: white;
    font-size: 18px;
    position: relative;
    left: 1px;
    min-width: 18px;
}

#chat_history_type_child_inp_smile_master {
    margin-left: auto;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    cursor: pointer;
}

#chat_history_type_child_inp_smile_master:hover {
    background: rgb(230, 230, 230);
}

#chat_history_type_child_inp_smile_icon {
    font-size: 20px;
    min-width: 20px;
    color: rgb(92, 92, 92);
}

#chat_history_type_child_plus_master {
    height: 35px;
    min-height: 35px;
    width: 35px;
    min-width: 35px;
    border-radius: 100px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

#chat_history_type_child_plus_master:hover {
    background: rgb(230, 230, 230);
}

#chat_history_type_child_plus_icon {
    font-size: 28px;
    color: rgb(92, 92, 92);
    min-width: 28px;
}

#chat_history_type_child_inp_inp {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
}

#chat_history_chats_bg {
    display: flex;
    flex-direction: column;
    top: 0;
    left: 472px;
    position: fixed;
    height: 100%;
    width: calc(100% - 472px);
    padding: 64px 10px;
    /* cursor: default; */
    /* -webkit-user-select: none; */
    /* user-select: none; */
    z-index: 0;
    background: rgb(238, 233, 225);
}

#chat_history_chats_master {
    display: flex;
    flex-direction: column;
    top: 0;
    left: 472px;
    position: absolute;
    /* height: 100%; */
    width: calc(100% - 472px);
    padding: 64px 10px;
    /* cursor: default; */
    /* -webkit-user-select: none; */
    /* user-select: none; */
    z-index: 1;
}

#chat_history_chat_img {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: cover;
    /* transform: rotate(45deg); */
}

.chat_history_chats_chil_me {
    margin-bottom: 4px;
    width: fit-content;
    padding: 10px;
    margin-left: auto;
    max-width: 80%;
    color: rgb(50, 58, 48);
    font-size: 14px;
    font-weight: 400;
    border-radius: 10px;
    background: rgb(217, 253, 211);
}

.chat_history_chats_chil_other {
    margin: 4px 0px;
    width: fit-content;
    padding: 10px;
    margin-left: right;
    max-width: 80%;
    color: rgb(59, 59, 59);
    font-size: 14px;
    font-weight: 400;
    border-radius: 10px;
    background: rgb(255, 255, 255);
}