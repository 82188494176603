#dimscreen {
  visibility: hidden;
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(1px);
  background: rgba(30, 33, 36, 0.2);
  z-index: 101;
  opacity: 0;
  transition: 0.2s;
}

#dimscreen.active {
  visibility: visible;
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(1px);
  background: rgba(30, 33, 36, 0.2);
  z-index: 101;
  opacity: 1;
  transition: 0.2s;
}
.setting-container {
  font-family: Arial, sans-serif;
  margin: 0 auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 96%;
  height: 95h;
}

.setting-bar {
  position: relative;
  width: 100%;
  height: 200px;
  background-image: url("../../assets/navbar-background.png");
  background-size: cover;
  background-position: center;
}

.back-svg:hover {
  transform: scale(1.2);
  fill: #fd2702;
}

.setting-title {
  position: absolute;
  top: 60%;
  left: 6%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 2rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.setting-logo {
  width: 100px;
  height: 50px;
  margin-right: 10px;
}
.body-part {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.body-heading-del-btn {
  display: flex;
  align-items: flex-end;
  gap: 22px;
  padding-top: 15px;
}
.setting-content {
  display: flex;
  gap: 20px;
}

.room-info-panel {
  flex: 1;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.user-settings-panel {
  flex: 1;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.input-field {
  width: 95%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}
.form-group-1 {
  position: relative;
  margin-bottom: 15px;
}

.input-field-mail {
  width: 91% !important;
  padding: 10px;
  padding-left: 40px !important;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
}

.mail-icon {
  position: absolute;
  left: 10px;
  top: 68%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  fill: #cfd0d1;
}

.upload-area {
  padding: 20px;
  border: 2px dashed #ddd;
  text-align: center;
  color: #888;
  border-radius: 4px;
  cursor: pointer;
  background: #fafafa;
}
.settings-toggle {
  display: flex;
  gap: 10px;
}
.labeltoggle-1 {
  display: flex;
  flex-direction: column;
}
.labeltoggle-2 {
  display: flex;
  flex-direction: column;
}
.labeltoggle-3 {
  display: flex;
  flex-direction: column;
}
.labeltoggle-4 {
  display: flex;
  flex-direction: column;
}
.labeltoggle-5 {
  display: flex;
  flex-direction: column;
}
.labeltoggle-6 {
  display: flex;
  flex-direction: column;
}
.toggle-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 50px;
  height: 24px;
  background-color: #ccc;
  border-radius: 50px;
  position: relative;
  transition: background-color 0.3s ease;
}

.toggle-button.active {
  width: 50px;
  height: 24px;
  background-color: #4caf50;
}

.toggle-circle {
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  transition: transform 0.3s ease;
}

.toggle-button.active .toggle-circle {
  transform: translateX(30px);
}

.delete-button {
  background: #ff4d4f;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 20px;
  transition: background 0.3s ease, transform 0.3s ease;
  outline: none;
}

.delete-button:hover {
  background: #d85140;
  transform: scale(1.05);
}

.cancel-button {
  padding: 10px 30px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  color: #000;
  background-color: #fff !important;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.cancel-button:hover {
  transform: scale(1.05);
}

.save-button {
  padding: 10px 40px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  background-color: #f44336;
  color: white;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.save-button:hover {
  background-color: #d32f2f;
  transform: scale(1.05);
}

.cancel-button {
  background: #ddd;
  color: #333;
  margin-right: 10px;
}

.save-button {
  background: #4caf50;
  color: white;
}

.cancel-button:hover {
  background: #ccc;
}

.save-button:hover {
  background: #45a047;
}
